import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/oatoolsonline/login',
    name: 'login',
    component: () => import('../views/LoginPage.vue')
  },
  {
    path: '/oatoolsonline/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/oatoolsonline/CICForLocal',
    name: 'CICForLocal',
    component: () => import('../views/CICForLocal.vue')
  },
  {
    path: '/oatoolsonline/CICForOnline',
    name: 'CICForOnline',
    component: () => import('../views/CICForOnline.vue')
  },
  {
    path: '/oatoolsonline/CICForLessonReportChecking',
    name: 'CICForLessonReportChecking',
    component: () => import('../views/CICForLessonReportChecking.vue')
  },
  {
    path: '/oatoolsonline/admin',
    name: 'admin',
    component: () => import('../views/AdminPage.vue')
  },
  {
    path: '*', // catch all route
  },
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router

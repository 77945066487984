<template>
  <div id="app">
    <nav v-if="loggedIn">
      <!-- <router-link to="/">首頁</router-link> | -->
      <router-link
        to="/oatoolsonline/about"
        :is="processing ? 'span' : 'router-link'"
      >關於</router-link>
      <span v-if="permission.CICForLocal"> | </span>
      <router-link
        v-if="permission.CICForLocal"
        to="/oatoolsonline/CICForLocal"
        :is="processing ? 'span' : 'router-link'"
      >實體對班級</router-link>
      <span v-if="permission.CICForOnline"> | </span>
      <router-link
        v-if="permission.CICForOnline"
        to="/oatoolsonline/CICForOnline"
        :is="processing ? 'span' : 'router-link'"
      >線上對班級</router-link>
      <span v-if="permission.CICForLessonReportChecking"> | </span>
      <router-link
        v-if="permission.CICForLessonReportChecking"
        to="/oatoolsonline/CICForLessonReportChecking"
        :is="processing ? 'span' : 'router-link'"
      >班級紀錄檢查</router-link>
      <span v-if="permission.admin"> | </span>
      <router-link
        v-if="permission.admin"
        to="/oatoolsonline/admin"
        :is="processing ? 'span' : 'router-link'"
      >管理員後台</router-link>
    </nav>
    <router-view
      @login-success="checkLoginStatus"
      @logout-success="checkLoginStatus"
      @start-processing="processing = true"
      @finish-processing="processing = false"
    />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      loggedIn: false,
      permission: {
        admin: false,
        CICForLocal: false,
        CICForOnline: false,
        CICForLessonReportChecking: false
      },
      processing: false
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    checkLoginStatus() {
      if (localStorage.getItem('token')) {
        this.loggedIn = true
      } else {
        this.loggedIn = false
      }

      if (localStorage.getItem('permission')) {
        const permission = JSON.parse(localStorage.getItem('permission'))
        this.permission.admin = permission.admin
        this.permission.CICForLocal = permission.CIC_for_local
        this.permission.CICForOnline = permission.CIC_for_online
        this.permission.CICForLessonReportChecking = permission.CIC_for_lesson_report_checking
      }
    }
  },
  beforeMount() {
    this.checkLoginStatus();
  },
  mounted() {
    this.scrollToTop();
  }
}
</script>

<style lang="scss">
#app {
  font-family: 'Courier New', Courier, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
